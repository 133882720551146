@import url("https://fonts.googleapis.com/css?family=Manrope:200,300,400,500,600,700&display=swap");

.root {
    display: flex;
    flex-direction: column;
    margin: -8px;
    color: #1f1f1f;
    width: 100vw;
    overflow-x: hidden;
}

.content {
    // display: grid;
    // grid-template-columns: 100%;
    // grid-gap: 1px;
    // grid-auto-rows: 100vh;
    width: 100vw;
}

.firstSection {
    width: 100vw;
    // height: 100vh;
    background: #e6eaf9 ;

    .logo {
        margin: 9px;
    }

    .titleBlock {
        display: flex;
        align-items: center;
        text-align: center;
        // margin-bottom: 5vh;
    }

    .title {
        font-weight: 500;
        font-size: 2rem;
    }

    .description {
        font-weight: 400;
        color: #4d4d4d;
        text-align: center;
        font-size: 21px;
        max-width: 350px;
        margin: 18px 0px;
    }

    .firstSectionContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .image {
        height: 300px;
        width: auto;
        overflow: hidden;
        border-radius: 1.5rem;
        // margin-top: 5vh;
        padding: 12px;
    }

    .texts {
        max-width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }

    .buttons {
        // margin-top: 5vh;
        display: flex;
        
        .consumer {
            text-transform: none;
            width: 316px;
            height: 70px;
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            color: #ffffff;
            background: #2c3d8f;
            box-shadow: none;
            border-radius: 0.75rem;
        }
    }
}

.secondSection {
    // background: #A8B1CE ;
    background: #2c3d8f ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // height: 100vh;

    .title {
        text-align: center;
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 18px;
        color: #ffffff;
        margin-top: 18px;
        overflow-wrap: break-word;
        padding: 0px 18px;
    }

    .outer {
        display: flex;
        flex-direction: column; 
        margin-top: auto;
        margin-bottom: auto;

        .phase {
            display: flex;
            padding: 30px;
            text-align: center;
            align-items: center;
            flex-direction: column;

            .image {
                border-radius: 9rem;
                width: 100px;
            }

            .text {
                max-width: 300px;
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
                margin-top: 30px;
                // color: #4d4d4d;
                color: #ffffff;
                text-align: center;
            }
        }

        .loader {
            display: flex;
            justify-content: center;
            .first {
                width: 20px;
                height: 20px;
                background: #ffffff;
                border-radius: 10rem;
                opacity: 0.2;
                margin-right: 18px;
            }

            .second {
                width: 20px;
                height: 20px;
                background: #ffffff;
                border-radius: 10rem;
                opacity: 0.6;
                margin-right: 18px;
            }

            .third {
                width: 20px;
                height: 20px;
                background: #ffffff;
                border-radius: 10rem;
            }
        }
    }

    .bottomText {
        margin-top: auto;
        margin-bottom: 9px;
        color: #ffffff;
    }   
}

.thirdSection {
    background: #e6eaf9 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: max-content;
    // display: grid;
    // grid-auto-rows: max-content 1fr;

    .top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            font-weight: 500;
            font-size: 2rem;
            color: #1f1f1f;
            padding-top: 18px;
        }

        .description {
            font-weight: 400;
            margin-top: 18px;
            color: #4d4d4d;
            text-align: center;
            font-size: 24px;
        }
    }

    .cardSection {
        display: flex;
        justify-content: space-around;
        margin-top: 18px;
        flex-direction: column;

        .card {
            height: max-content;
            background: #f8f9fd;
            width: max-content;
            display: flex;
            flex-direction: column;
            border-radius: 1rem;
            width: 200px;
            margin-bottom: 18px;

            .imageInner {
                width: 200px;
                border-radius: 1rem 1rem 0rem  0rem ;
            }
    
            .texts {
                display: flex;
                flex-direction: column;
                padding: 9px;
                max-width: 332px;
                align-items: center;
    
                .cardTitle {
                    font-weight: 600;
                    font-size: 16px;
                    color: #1f1f1f;
                }
    
                .description {
                    font-weight: 400;
                    color: #1f1f1f;
                    text-align: center;
                    font-size: 12px;
                }
            }
        }
    }

    .footer {
        background: #2c3d8f;
        color: #ffffff;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100vw;

        .pet {
            background: white;
            width: max-content;
            padding: 2px;
            border-radius: 3PX;
    
            .petLogo {
                width: 50px;
            }
        }

        .findUs {
            display: flex;
            text-align: center;

            .haifa {
                display: flex;
                flex-direction: column;

                .description {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 20px;
                }

                .link {
                    display: flex;

                    .linkText {
                        font-weight: 400;
                        font-size: 18px;
                        color: #ffffff;
                        line-height: 20px;
                        margin-bottom: 7px;
                        // margin-left: 5px;
                    }
                }
            }
        }

        .email {
            .link {
                font-weight: 400;
                font-size: 18px;
                color: #ffffff;
                line-height: 20px;
                margin-bottom: 7px;
                // margin-left: 5px; 
            }
        }

        .social {
            .icon {
                fill: #ffffff;
                height: 39px;
                width: 39px;
            }
        }

        .copyRight {
            .description {
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .separator {
        width: 10px;
        height: 10px;
        border-radius: 10rem;
        background: #ffffff;
        margin: 9px;
    }
    
    .separatorFirst {
        width: 10px;
        height: 10px;
        border-radius: 10rem;
        background: #ffffff;
        opacity: 0.2;
        margin: 9px;
    }

    .separatorSecond {
        width: 10px;
        height: 10px;
        border-radius: 10rem;
        background: #ffffff;
        opacity: 0.5;
        margin: 9px;
    }

    .separatorThird {
        width: 10px;
        height: 10px;
        border-radius: 10rem;
        background: #ffffff;
        opacity: 0.8;
        margin: 9px;
    }
}

