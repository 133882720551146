.main {
    background: #E5E5E5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    .content {
        display: grid;
        grid-template-columns: 1fr 860px 1fr;
        justify-items: center;

        .preview {
            width: 840px;
            height: 1200px;
            overflow: auto;
            display: flex;
            align-self: center;
            margin-top: 39px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            margin-bottom: 9px;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #D9D9D9;
                padding: 34px 40px 28px 40px;
        
                .name {
        
                    .title {
                    }
                    
                    .experiment {
                        color: #A5A4A2;
                        font-weight: 200;
                    }
                }
        
                .pages {
                    .text {
                        color: #2C3D8F;
                    }
                }
            }

            .mail {
                padding: 0px 20px;
            }

            .submit {
                border-top: 1px solid #D9D9D9;
                padding: 52px 47px;

                .text {
                    padding-bottom: 16px;
                }
            }
        }
    }
}

.submitted {
    background: #E7EDEC;
    height: 100vh;
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        .text {
            color: #2C3D8F;
            font-size: 30px;
            line-height: 42px;
            font-weight: 200;
        }

        .scoreTitle {
            color: #2C3D8F;
            text-align: center;
            font-size: 22px;
            line-height: 28px;
            font-weight: 200;
            margin: 3px 50px;
        }

        .score {
            color: #2C3D8F;
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            font-weight: 200;
            margin: 3px 50px;
        }
    }
}


// .content {
    // padding: 46px 0px;
    // background: #E5E5E5;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;

//     .preview {
        // width: 840px;
        // height: 1200px;
        // background: #FFFFFF;
        // box-shadow: 0px 4px 20px rgb(0 0 0 / 6%);
        // border-radius: 12px;
        // padding: 20px;
        // overflow: auto;
//     }
// }