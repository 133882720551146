.number_input {
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
        margin-bottom: 9px;
    }

    .slider {
        min-width: 200px;
        margin: 0px 18px;
    }
}

.on {
    display: flex;
    flex-direction: row;
    align-items: center;
}