.topbar {
    display: flex;
    height: 80px;
    // width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    align-items: center;
    justify-content: space-between;

    .avatar {
        display: flex;
    }
}

.username {
    margin-right: 46px;
}