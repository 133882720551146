.main {
    display: grid;
    grid-template-rows: 80px 1fr;
    width: 100%;
    min-height: 100vh;

    .content {
        height: 100%;
    }

    .topBar {
        flex-shrink: 1;
    }
}
