.logo {
    display: flex;
    align-items: center;
    text-align: center;

    .icon {
        margin-right: 3px;
    }

    .text {
        color: #2C3D8F;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }
}

.top {
    margin: 9px;
    width: min-content;
    // background: #F8F9FD;
    padding: 9px;
    border-radius: 0.5rem;
}

.paddingTop {
    padding-top: 38px;
}

.image {
    width: 39px;
    margin: 9px;
}

.separator {
    width: 2px;
    height: 35px;
    background: #2C3D8F;
    margin: 0 9px;
    opacity: 10%;
}

.contributes {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(255 255 255 / 15%);
    margin-left: 18px;
    opacity: 0.95;
}