html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

.content {
    // display: flex;
    // overflow: scroll;
    // background: #FFFFFF;

    .preview {
        // width: 100vw;
        // height: 1200px;
        // overflow: auto;
        // display: flex;
        // align-self: center;
        // display: flex;
        // flex-direction: column;
        // margin-bottom: 9px;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        .header {
            position: sticky;
            top: 0;
            z-index: 10000;

            .headerContent {
                display: flex;
                background: #FFFFFF;
                justify-content: space-between;
                padding: 20px;
                border-bottom: 1px solid #D9D9D9;
                z-index: 10000000;
        
                .name {
        
                    .title {
                    }
                    
                    .experiment {
                        color: #A5A4A2;
                        font-weight: 200;
                    }
                }
        
                .pages {
                    display: flex;
                    align-items: center;

                    .text {
                        color: #2C3D8F;
                        padding: 0px 6px;
                    }

                    .spaceHolder {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .mail {
            padding: 0px 20px;
        }

        .submit {
            padding: 20px;
            .text {
                padding-bottom: 16px;
            }
        }
    }
}

.submitted {
    height: 100vh;
    background: #E7EDEC;
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        background: #E7EDEC;
        text-align: center;

        .text {
            color: #2C3D8F;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
        }

        .scoreTitle {
            text-align: center;
            color: #2C3D8F;
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
            margin: 3px 9px;
        }

        .score {
            text-align: center;
            color: #2C3D8F;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            margin: 3px 9px;
        }
    }
}
