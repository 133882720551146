@media only screen and (max-width: 767px) {
    .root {
        background: #F2F5FF;
        height: 100vh;
        margin: -8px;
    
        .content {
            display: flex;
            flex-direction: column;
    
        .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 55px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #2C3D8F;
            margin: 60px 0px 30px 20px;
        }
    
        .box {
            // background: #FFFFFF;
            // box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.2);
            // border-radius: 10px;
            // display: flex;
            // flex-direction: column;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            width: calc(100vw - 40px);
    
            .boxtitle {
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                color: #2C3D8F;
                margin-bottom: 11px;
            }
    
            .textfield {
                max-width: 100%;
                background: #FFFFFF;
            }
    
            .submit {
                margin-top: 18px;
                text-transform: none;
                height: 60px;
                background: #2C3D8F;
                border-radius: 8px;
                font-weight: 400;
                font-size: 20px;
                line-height: 61px;
            }
    
            .error {
                color: red;
                margin: 3px 0px;
            }
    
            .emptyError {
                height: 26px;
            }
        }
        }
        }
    }
    
    @media only screen and (min-width: 768px) {
        .root {
            background: #F2F5FF;
            height: 100vh;
            margin: -8px;
    
            .logo {
                display: flex;
                align-items: center;
                padding-top: 38px;
                padding-left: 33px; 
            
                .text {
                    color: #2C3D8F;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    margin-left: 10px;
                }
            }
    
            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
    
            .title {
                font-weight: 400;
                font-size: 38px;
                line-height: 55px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #2C3D8F;
                margin: 60px 0px 30px 0px;
            }
    
            .box {
                background: #FFFFFF;
                box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                padding: 50px;
    
                .boxtitle {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 20px;
                    color: #2C3D8F;
                    margin-bottom: 11px;
                }
    
                .textfield {
                    // margin-bottom: 1px;;
                    // border: 2px solid #2C3D8F;
                    // border-radius: 8px;
                    max-width: 382px;
                    min-width: 250px;
                    background: #FFFFFF;
                    // height: 65px;
                }
    
                .submit {
                    margin-top: 18px;
                    text-transform: none;
                    height: 60px;
                    background: #2C3D8F;
                    border-radius: 8px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 61px;
                }
    
                .error {
                    color: red;
                    margin: 3px 0px;
                }
    
                .emptyError {
                    height: 26px;
                }
            }
            }
        }
    }