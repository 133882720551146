.root {
    width: 100%;
    height: 100%;
    background: #E5E5E5;

    .innerContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        .titleSection {
            padding: 47px 86px 32px 86px;
            display: flex;
            justify-content: space-between;

            .back {
                display: flex;
                align-items: center;
                
                .title {
                    color: #2C3D8F;
                    font-size: 30px;
                    font-weight: 300;
                }
            }
            
            .create {
                // text-transform: none;
                color: #2C3D8F;
                font-size: 18px;
                font-weight: 400;
            }
        }

        .content {
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            margin: 0px 86px 66px 86px;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 30px;

            .title {
                color: #2F3032;
                font-weight: 400;
                margin-bottom: 27px;
            }

            .action {
                background: #2C3D8F;
                color: #FFFFFF;
            }

            .description {
                color: #2F3032;
                font-weight: 300;
                margin-bottom: 46px;
            
                .note {
                    color: #b7c9c7;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
            }

            .descriptionInput{
                margin-top: 18px;
                min-width: 500px;
            }

            .participants {
                display: flex;
                align-items: center;
                margin-bottom: 36px;
            
                .actions {
                    background: #2C3D8F;
                    color: #FFFFFF;
                    margin-left: 9px;
                }

                .actions:hover {
                    background: #b7c9c7;
                    color: #FFFFFF;
                    margin-left: 9px;
                }
            
                .text {
                    color: #2F3032;
                    font-weight: 300;
                    margin-bottom: 0;
                }
            }
            
            .rootAction {
                display: flex;
                justify-content: flex-start;
                align-items: end;
                flex-grow: 1;
                margin-bottom: 30px;

                .start {
                    // text-transform: none;
                    width: fit-content;
                    background: #2C3D8F;
                    color: #FFFFFF;
                }

                .start:disabled {
                    // text-transform: none;
                    width: fit-content;
                    background: #cfdbd9;
                    color: #FFFFFF;
                }

                .draft {
                    // text-transform: none;
                    width: fit-content;
                    background: #285e56;
                    color: #FFFFFF;
                    margin-left: 9px;
                }
            }
        }
    }
}
