.main{
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 9px 0px;

    ul.item {
        list-style-type: circle;
        // list-style-position: inside;
    }
    
    ol.item {
        list-style: decimal;
    }
    
    .validation {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .mainTextbox {
        display: flex;
        align-items: center;
    
        .textbox {
            margin: 9px;
        }
    }
    
    .image {
        object-fit: scale-down;
    }

    .button {
        width: 184px;
        height: 184px;
        background: rgba(16, 76, 67, 0.1);
        .arrow {

        }
    }
}

.preview_node {
    margin: 12px 0;
}

.preview_node_top {
 margin-top: 12px;   
}