.signin {
    display: flex;
    flex-direction: column;
    background: #F2F5FF;
    margin: -8px;
    width: 100vw;
    height: 100vh;
}

.image {
    margin-bottom: 10px;
    max-width: 50%;
    max-height: 50%;
    border-bottom-color: #d2d7da;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0px;

    .title {
        font-weight: 400;
        font-size: 40px;
        line-height: 55px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #2C3D8F;
        width: 507px;
    }
}


.googlesignin{
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgb(0 0 0 / 20%);
    border-radius: 10px;
    box-sizing: inherit;
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 45px;
}