.image {
    width: 50px;
    padding-right: 6px;
}

.delete {
    margin: 0px 3px;
}

.done {
    color: #19786a;
}

.failed {
    color: #d32f2f;
}

.failedDiv {
    margin: 0px 3px;
}

.zIndex {
    z-index: 1;
}