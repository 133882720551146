.mainTextbox {
    .textAndScan {
        display: flex;
        align-items: center;
  
        .textbox{
            margin: 9px 18px 9px 0px;
        }
    }
}

