.root {
    width: 100%;
    height: 100%;
    background: #E5E5E5;

    .innerContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        .titleSection {
            padding: 47px 86px 32px 86px;
            display: flex;
            justify-content: space-between;

            .title {
                color: #2C3D8F;
                font-size: 30px;
                font-weight: 300;
            }

            .create {
                // text-transform: none;
                color: #2C3D8F;
                font-size: 18px;
                font-weight: 400;
            }

            .back {
                display: flex;
                align-items: center;            }
        }

        .empty {
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            margin: 0px 86px 66px 86px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            
            .title {
                color: #2F3032;
                font-weight: 400;
                margin-bottom: 27px;
            }

            .description {
                color: #2F3032;
                font-weight: 300;
                margin-bottom: 46px;
            }

            .create {
                background: #2C3D8F;
                color: #FFFFFF;
            }
        }

        .table {
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            margin: 0px 86px 66px 86px;
            height: 100%;
        }
    }
}

.display {
    display: flex;
    align-items: center;

    .text {
        cursor: pointer;
        color: #2C3D8F;
    }
}

.status {
    .button {
        margin-right: 13px;
    }
}

.response {
    display: flex;
    .answer {
        margin-left: 3px;
    }
}

.pages {
    display: flex;
    justify-content: center;
    align-items: center;

    .placeholder {
      width: 39px;
      height: 39px;
    }

    .text {
      color: #2C3D8F;
      font-weight: 350;
      font-size: 23px;
      margin: 0px 9px;
    }
  }