.editor-container {
    .editor-content {
      padding: 46px 0px;
      background: #E5E5E5;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .ProseMirror-focused {
        outline: none;
      }
      
      .ProseMirror{
        > * + * {
          margin-top: 0.75em;
        }
      
        ul,
        ol {
          padding: 0 1rem;
        }
      
        width: 840px;
        height: 1200px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        padding: 20px;
        overflow: auto;
      }
    }

    div:has(> div> img) {
      display: inline-flex !important;
  }
  }
  
  ul[data-type="taskList"] {
      list-style: none;
      padding: 0;
  
      li {
          display: flex;
          align-items: center;
  
          > label {
              
          flex: 0 0 auto;
          margin-right: 0.5rem;
          }
      }
  
      input[type="checkbox"] {
          cursor: pointer;
      }
  }

  .has-focus {
    border-radius: 3px;
    box-shadow: 0 0 0 3px #68cef8;
  }
