
.root {
  .topBar {
    display: flex;
    height: 90px;
    border-bottom: 1px solid #D9D9D9;
    align-items: center;
    justify-content: space-between;
    padding-left: 51px;
    padding-right: 33px;

    .input {
      display: flex;
      align-items: center;
    }

    .title {
      font-weight: 400;
      font-size: 30px;
      line-height: 28px;
      color: #2C3D8F;
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;

      .placeholder {
        width: 39px;
        height: 39px;
      }

      .text {
        color: #2C3D8F;
        font-weight: 350;
        font-size: 23px;
        margin: 0px 9px;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .draft {
        color: #2C3D8F;
        margin-right: 15px;
      }

      .submit {
        color: #FFFFFF;
        background: #2C3D8F;
        border-radius: 8px;
      }
    }
  }
}
